<div class="col">
    <div id="instructions">
        Find out if your picture has an otter or not!
    </div>
    <img id="otterpng" class="rounded mx-auto text-center d-block" style="max-height: 350px; max-width: 350px; opacity:{{ opac }}" #otter src="{{ imgURL }}" alt="otter">
    <div class="row mt-2 justify-content-center ">
        <input class="inputfile" type="file" id="file" name="file"  (change)="onFileSelected($event)">
        <label class="btn btn-primary" type="button" for="file">select</label>
        <div id="spacer" style="width:20px"></div>
        <label id="ab" class="btn btn-primary" type="button" (click)="onUpload()">submit</label>
    </div>
    <div id="result">
        {{ result }}
    </div>
</div>