<div id="header">
  <nav
    class="navbar navbar-expand navbar-dark"
    style="background-color: #99c1df"
  >
    <div class="collapse navbar-collapse flex-column justify-content-center">
      <div class="flex-row">
        <div class="flex-grow-1"></div>
        <a
          class="navbar-brand mr-auto"
          style="
            font-size: 40px;
            font-family: 'Gochi Hand', cursive;
            word-spacing: 8px;
          "
          >otter or not</a
        >
        <div class="flex-grow-1"></div>
      </div>
      <div
        class="collapse navbar-collapse flex-row justify-content-center"
        id="navbarText"
      >
        <div class="flex-grow-1"></div>
        <ul class="navbar-nav mr-auto" routerLinkActive="active">
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="home"
              style="font-size: 20px; font-family: 'Gochi Hand', cursive"
              >home</a
            >
          </li>
        </ul>
        <div id="spacer" style="width: 40px"></div>
        <ul class="navbar-nav mr-auto" routerLinkActive="active">
          <li class="nav-item">
            <a
              class="red nav-link"
              routerLink="about"
              style="font-size: 20px; font-family: 'Gochi Hand', cursive"
              >about</a
            >
          </li>
        </ul>
        <div class="flex-grow-1"></div>
      </div>
    </div>
  </nav>
</div>
<div id="content">
  <router-outlet></router-outlet>
</div>
<div id="footer">
  Otter or Not
  <br />
  © Maggie Cheung & Nic Marchini
</div>
