import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { default as anime } from '../../../node_modules/animejs/lib/anime.es.js';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  selectedFile: File = null;
  imgURL = null;
  result = '';
  opac = 1;

  constructor(private http: HttpClient) {}

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onUpload() {
    anime({
      targets: '#otterpng',
      opacity: 1,
      duration: 1,
      easing: "linear"
     });
     anime({
      targets: '#result',
      opacity: 0,
      duration: 1,
      easing: "linear"
     });
    this.result = '';
    const fd = new FormData();
    const re = new FileReader();
    re.readAsDataURL(this.selectedFile)
    re.onload = () => {
      // console.log(re.result);
      let result = re.result.slice(22)
      let body = { "image": result}
      // console.log("fd: ", fd);
      this.http.post('https://5q8ke52v64.execute-api.us-east-1.amazonaws.com/upload', body,
         {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe(event => {
          // console.log("res:", event);
          if (event['body']){
            this.result = event['body']
            anime({
              targets: '#result',
              opacity: 1,
              duration: 1000,
              easing: "linear"
             });
            anime({
              targets: '#otterpng',
              opacity: 0.15,
              duration: 600,
              easing: "linear"
          });
          }
        })

    };
    // fd.append('image', this.selectedFile, this.selectedFile.name);

      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile); 
      reader.onload = (_event) => { 
          this.imgURL = reader.result;
      }

    }
    
  ngOnInit(): void {    
    this.imgURL = "../assets/blank.png";
    
  }

  ngAfterViewInit() {
    //do nothing
   }

}
