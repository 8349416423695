<div class="container">
  <div class="col">
    <div class="row">
      <div class="col" style="max-height: 400px">
        <div class="shadow-lg p-3 mb-2 bg-white text-black text-center rounded">
          <img src="https://magirooni.s3.amazonaws.com/oto+magi.png" />
          <h4 id="name-text">Maggie Cheung</h4>
          <h5 id="sub-name">23 / she, her, hers</h5>
        </div>
      </div>
      <div class="col" style="max-height: 400px">
        <div class="shadow-lg p-3 mb-2 bg-white text-black text-center rounded">
          <img src="https://magirooni.s3.amazonaws.com/oto+nic.png" />
          <h4 id="name-text">Nic Marchini</h4>
          <h5 id="sub-name">22 / he, him, his</h5>
        </div>
      </div>
    </div>
    <div class="row-fluid justify-self-center align-self-center">
      <div class="shadow-lg p-4 my-4 bg-white rounded">
        <h4 id="blurb">
          What is
          <h4 id="name-text" style="display: inline">otter or not?</h4>
        </h4>
        <ul id="blurb">
          <li>
            otter or not is a binary image classifier served by a web app.
          </li>
          <li>
            users can upload pictures to run the model on and get back a
            classification status.
          </li>
          <li>this status will say whether it's an otter or not!</li>
        </ul>
      </div>
    </div>
  </div>
</div>
